/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://tqwhhqn5lncdbbmd62fsw5fl24.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-cslgvpbluzesjgys346ynlsafe",
    "aws_cloud_logic_custom": [
        {
            "name": "emailverificationapi",
            "endpoint": "https://ws3fqznc1b.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
