import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "12rem",
    width: "12rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function CircularIndeterminate({ loading, setLoading }) {
  const classes = useStyles();

  return (
    <Dialog
      open={loading}
      onClose={() => setLoading(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.root}>
        <CircularProgress size={"8rem"} thickness={4} />
      </div>
      <Box my={2}>
        <Typography align={"center"} variant={"h5"}>
          Einzugsmeldung wird erstellt
        </Typography>
      </Box>
    </Dialog>
  );
}
