import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { API, graphqlOperation } from "aws-amplify";
import { getApplicant } from "../graphql/queries";
import { updateApplicant } from "../graphql/mutations";

const SendTelefax = ({ history }) => {
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [displayLink, setDisplayLink] = useState(false);

  const clickHandler = () => {};

  useEffect(() => {
    const sentFax = async () => {
      try {
        const applicantDoc = await API.graphql(
          graphqlOperation(getApplicant, { id })
        );
        console.log("applicantDoc:", applicantDoc);
        if (!applicantDoc.data || !applicantDoc.data.getApplicant) {
          setMessage("You have NOT lodged an application yet.");
          setDisplayLink(true);
        } else {
          if (applicantDoc.data.getApplicant.isTelefaxed) {
            setMessage("Your application has already been telefaxed.");
          } else {
            const now = new Date().toISOString();
            API.graphql(
              graphqlOperation(updateApplicant, {
                input: {
                  id,
                  isVerified: true,
                  verifiedAt: now,
                },
              })
            );
            API.post("emailverificationapi", "/telefax", {
              body: { id },
            });
            history.push("/success");
          }
        }
      } catch (error) {
        console.log("request error:");
        console.log(error);
      }
    };
    sentFax();
  }, [id, history]);

  return (
    <>
      {message && (
        <>
          <Box my={5}>
            <Typography component="h1" variant="h4">
              Caution!
            </Typography>
          </Box>
          <Box my={3}>
            <Typography component="h3" variant="h5">
              {message}
            </Typography>
          </Box>
        </>
      )}
      {displayLink && (
        <Box my={3}>
          <Button variant="outlined" color="primary" onClick={clickHandler}>
            <Link to="/" style={{ textDecoration: "none" }}>
              Lodge an application
            </Link>
          </Button>
        </Box>
      )}
    </>
  );
};

export default SendTelefax;
