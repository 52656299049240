/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApplicant = /* GraphQL */ `
  query GetApplicant($id: ID!) {
    getApplicant(id: $id) {
      id
      lastName
      firstName
      dob
      address
      zip
      jurisdiction
      fax
      city
      phone
      email
      noFacility
      facilityName
      facilityAddress
      facilityZip
      facilityCity
      facilityDate
      hasContact
      contactFirstName
      contactLastName
      contactAddress
      contactZip
      contactCity
      contactPhone
      contactEmail
      isApplicationForm
      isConsultation
      isVerified
      verifiedAt
      isTelefaxed
      telefaxedAt
      applicationFormURL
      createdAt
      updatedAt
    }
  }
`;
export const listApplicants = /* GraphQL */ `
  query ListApplicants(
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastName
        firstName
        dob
        address
        zip
        jurisdiction
        fax
        city
        phone
        email
        noFacility
        facilityName
        facilityAddress
        facilityZip
        facilityCity
        facilityDate
        hasContact
        contactFirstName
        contactLastName
        contactAddress
        contactZip
        contactCity
        contactPhone
        contactEmail
        isApplicationForm
        isConsultation
        isVerified
        verifiedAt
        isTelefaxed
        telefaxedAt
        applicationFormURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const applicantByEmail = /* GraphQL */ `
  query ApplicantByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastName
        firstName
        dob
        address
        zip
        jurisdiction
        fax
        city
        phone
        email
        noFacility
        facilityName
        facilityAddress
        facilityZip
        facilityCity
        facilityDate
        hasContact
        contactFirstName
        contactLastName
        contactAddress
        contactZip
        contactCity
        contactPhone
        contactEmail
        isApplicationForm
        isConsultation
        isVerified
        verifiedAt
        isTelefaxed
        telefaxedAt
        applicationFormURL
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
