import React, { useState, useEffect } from "react";
import axios from "axios";
import XLSX from "xlsx";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "./Alert";
import Loading from "./Loading";
import { API, graphqlOperation } from "aws-amplify";
import { applicantByEmail } from "../graphql/queries";
import { createApplicant, updateApplicant } from "../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(10),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default function LandingPage({ history }) {
  const classes = useStyles();
  const today = new Date();
  const defaultDOB = new Date(1970, 1, 1);
  const [jurisdictions, setJurisdictions] = useState([]);
  const [filteredJurisdiction, setFilteredJurisdictions] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zipError, setZipError] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [inputValues, setInputValues] = useState({
    lastName: "",
    firstName: "",
    dob: defaultDOB.toISOString().slice(0, 10),
    address: "",
    // streetNumber: "",
    zip: "",
    jurisdiction: "",
    fax: "",
    city: "",
    // country: "",
    phone: "",
    email: "",
    noFacility: false,
    facilityName: "",
    facilityAddress: "",
    facilityZip: "",
    facilityCity: "",
    facilityDate: today.toISOString().slice(0, 10),
    hasContact: false,
    contactFirstName: "",
    contactLastName: "",
    contactAddress: "",
    // contactStreetNumber: "",
    contactZip: "",
    contactCity: "",
    // contactCountry: "",
    contactPhone: "",
    contactEmail: "",
    isApplicationForm: true,
    isConsultation: true,
  });

  const calculateAge = (dob) => {
    const dobObj = new Date(inputValues.dob);
    const diff_ms = Date.now() - dobObj.getTime();
    const age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  useEffect(() => {
    const getJurisdiction = async () => {
      const response = await axios.get(
        "https://telefax-jurisdiction.s3.eu-central-1.amazonaws.com/jurisdiction-fax.xlsx",
        { responseType: "arraybuffer" }
      );
      const content = new Uint8Array(response.data);
      const workSheet = XLSX.read(content, { type: "array" }).Sheets["Sheet1"];
      const jurisdictions = XLSX.utils.sheet_to_json(workSheet);
      setJurisdictions(jurisdictions);
    };
    getJurisdiction();
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  const zipHandler = (e) => {
    const re = /^[0-9]{5}$/;
    if (re.test(e.target.value)) {
      setZipError(false);
      if (e.target.value.length === 5) {
        const foundJurisdictions = jurisdictions.filter(
          (x) => x.zip === e.target.value
        );
        if (foundJurisdictions.length > 0) {
          if (foundJurisdictions.length > 1) {
            setFilteredJurisdictions(foundJurisdictions);
            setInputValues({
              ...inputValues,
              zip: e.target.value,
              jurisdiction: "",
              fax: "",
            });
          } else {
            setFilteredJurisdictions([]);
            const age = calculateAge(inputValues.dob);
            setInputValues({
              ...inputValues,
              zip: e.target.value,
              jurisdiction:
                foundJurisdictions[0].age_less_than &&
                age < foundJurisdictions[0].age_less_than
                  ? foundJurisdictions[0].jurisdiction_2
                  : foundJurisdictions[0].jurisdiction,
              fax:
                foundJurisdictions[0].age_less_than &&
                age < foundJurisdictions[0].age_less_than
                  ? foundJurisdictions[0].fax_2
                  : foundJurisdictions[0].fax,
            });
          }
        } else {
          setFilteredJurisdictions([]);
          setInputValues({
            ...inputValues,
            zip: e.target.value,
            jurisdiction: "",
            fax: "",
          });
        }
      } else {
        setFilteredJurisdictions([]);
        setInputValues({
          ...inputValues,
          zip: e.target.value,
          jurisdiction: "",
          fax: "",
        });
      }
    } else {
      setFilteredJurisdictions([]);
      setZipError(true);
      setInputValues({
        ...inputValues,
        zip: e.target.value,
        jurisdiction: "",
        fax: "",
      });
    }
  };

  const handleJurisdiction = (e) => {
    const fax = filteredJurisdiction.find(
      (x) => x.jurisdiction === e.target.value
    ).fax;
    setInputValues({
      ...inputValues,
      fax,
      jurisdiction: e.target.value,
    });
  };

  const selectorHandler = (e) => {
    const { name } = e.target;
    if (name === "no-email") {
      setNoEmail(!noEmail);
      if (!noEmail) setInputValues({ ...inputValues, hasContact: true });
      else setInputValues({ ...inputValues, hasContact: false });
    } else
      setInputValues({
        ...inputValues,
        [name]: !inputValues[name],
      });
  };

  const sendVerificationEmail = async (applicant) => {
    await API.post("emailverificationapi", "/email", {
      body: { applicant },
    });
  };

  const updateApplicantAndSendVerificationEmail = async () => {
    setAlert(false);
    setLoading(true);
    try {
      const updatedResponse = await API.graphql(
        graphqlOperation(updateApplicant, {
          input: {
            id: updateId,
            ...inputValues,
            isVerified: false,
            isTelefaxed: false,
            jurisdiction: inputValues.jurisdiction || "Default Jurisdiction",
            fax:
              inputValues.fax && inputValues.fax[0] === "+"
                ? inputValues.fax
                : "+4982199973711",
          },
        })
      );
      sendVerificationEmail(updatedResponse.data.updateApplicant);
      setLoading(false);
      history.push("/prompt");
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const existedApplicant = await API.graphql(
        graphqlOperation(applicantByEmail, { email: inputValues.email })
      );
      if (existedApplicant.data.applicantByEmail.items.length !== 0) {
        const id = existedApplicant.data.applicantByEmail.items[0].id;
        setUpdateId(id);
        setLoading(false);
        setAlert(true);
      } else {
        const saveResponse = await API.graphql(
          graphqlOperation(createApplicant, {
            input: {
              ...inputValues,
              isVerified: false,
              isTelefaxed: false,
              jurisdiction: inputValues.jurisdiction || "defaultJurisdiction",
              fax:
                inputValues.fax && inputValues.fax[0] === "+"
                  ? inputValues.fax
                  : "+4982199973711",
            },
          })
        );
        sendVerificationEmail(saveResponse.data.createApplicant);
        setLoading(false);
        history.push("/prompt");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={classes.paper}>
        <Typography component="h1" variant="h4">
          Einzugsmeldung erstellen
        </Typography>
        <form className={classes.form} onSubmit={submitHandler}>
          {/* <Typography variant="h6">Applicant Info:</Typography> */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Nachname"
            name="lastName"
            autoComplete="family_name"
            value={inputValues.lastName}
            onChange={handleOnChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="Vorname"
            name="firstName"
            autoComplete="given_name"
            value={inputValues.firstName}
            onChange={handleOnChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="dob"
            label="Geburtsdatum"
            type="Date"
            id="dob"
            value={inputValues.dob}
            onChange={handleOnChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="address"
            label="Adresse"
            name="address"
            autoComplete="street-address"
            value={inputValues.address}
            onChange={handleOnChange}
          />
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="streetNumber"
            label="Street Number"
            name="streetNumber"
            value={inputValues.streetNumber}
            onChange={handleOnChange}
          /> */}
          <TextField
            error={zipError}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="zip"
            label="Postleitzahl"
            name="zip"
            autoComplete="postal-code"
            value={inputValues.zip}
            onChange={zipHandler}
          />

          {filteredJurisdiction && filteredJurisdiction.length > 1 && (
            <TextField
              id="jurisdiction-selects"
              variant="outlined"
              margin="normal"
              fullWidth
              select
              label="Select Your Jurisdiction"
              name="jurisdiction"
              value={inputValues.jurisdiction}
              onChange={handleJurisdiction}
            >
              {filteredJurisdiction.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.jurisdiction}
                  fax={option.fax}
                >
                  {option.jurisdiction}
                </MenuItem>
              ))}
            </TextField>
          )}

          {/* {filteredJurisdiction && filteredJurisdiction.length === 1 && (
            <>
              <Typography align="right">
                Your Jurisdiction: {inputValues.jurisdiction}
              </Typography>
              <Typography align="right">
                Fax Number: {inputValues.fax}
              </Typography>
            </>
          )} */}

          {inputValues.jurisdiction &&
            !(filteredJurisdiction && filteredJurisdiction.length > 1) && (
              <Typography align="right">
                Zuständige Behörde: {inputValues.jurisdiction}
              </Typography>
            )}

          {/*inputValues.fax && inputValues.fax[0] === "+" && (
            <Typography align="right">Fax Number: {inputValues.fax}</Typography>
          )*/}

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="city"
            label="Ort"
            name="city"
            autoComplete="address-level2"
            value={inputValues.city}
            onChange={handleOnChange}
          />
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="country"
            label="Country"
            name="country"
            autoComplete="country"
            value={inputValues.country}
            onChange={handleOnChange}
          /> */}
          <TextField
            variant="outlined"
            margin="normal"
            // required
            fullWidth
            name="phone"
            label="Telefon"
            id="phone"
            autoComplete="phone"
            value={inputValues.phone}
            onChange={handleOnChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required={!noEmail}
            disabled={noEmail}
            fullWidth
            name="email"
            label="E-Mail"
            type="email"
            id="email"
            autoComplete="email"
            value={inputValues.email}
            onChange={handleOnChange}
          />
          <FormControlLabel
            mt={3}
            label={
              <Typography variant="h6">Ich habe keine E-Mail-Adresse</Typography>
            }
            control={
              <Checkbox
                checked={noEmail}
                name="no-email"
                color="primary"
                value={noEmail}
                onChange={selectorHandler}
              />
            }
          />
          <Typography>
            Sollten Sie keine E-Mail-Adresse haben, müssen Sie stattdessen eine Kontaktperson angeben.
          </Typography>
          <Divider variant="middle" className={classes.divider} />
          <Typography variant="h6">Pflegeeinrichtung:</Typography>
          <FormControlLabel
            mt={3}
            label={<Typography>Die Einrichtung steht noch nicht fest.</Typography>}
            control={
              <Checkbox
                checked={inputValues.noFacility}
                name="noFacility"
                color="primary"
                value={inputValues.noFacility}
                onChange={selectorHandler}
              />
            }
          />

          {!inputValues.noFacility && (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="facilityName"
                label="Name der Einrichtung"
                type="text"
                id="facilityName"
                value={inputValues.facilityName}
                onChange={handleOnChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="facilityAddress"
                label="Anschrift"
                autoComplete="street-address"
                type="text"
                id="facilityAddress"
                value={inputValues.facilityAddress}
                onChange={handleOnChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="facilityZip"
                label="Postleitzahl"
                type="facilityZip"
                id="facilityZip"
                value={inputValues.facilityZip}
                onChange={handleOnChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="facilityCity"
                label="Ort"
                type="facilityCity"
                id="facilityCity"
                value={inputValues.facilityCity}
                onChange={handleOnChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="facilityDate"
                label="Einzugsdatum"
                type="Date"
                id="facilityDate"
                value={inputValues.facilityDate}
                onChange={handleOnChange}
              />
            </>
          )}
          <Divider variant="middle" className={classes.divider} />
          {!noEmail ? (
            <FormControlLabel
              mt={3}
              label={<Typography variant="h6">Kontaktperson hinzufügen</Typography>}
              control={
                <Checkbox
                  checked={inputValues.hasContact}
                  name="hasContact"
                  color="primary"
                  value={inputValues.hasContact}
                  onChange={selectorHandler}
                />
              }
            />
          ) : (
            <Typography variant="h6">Kontaktperson:</Typography>
          )}
          {inputValues.hasContact && (
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                id="contactLastName"
                label="Nachname"
                name="contactLastName"
                autoComplete="family-name"
                value={inputValues.contactLastName}
                onChange={handleOnChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                id="contactFirstName"
                label="Vorname"
                name="contactFirstName"
                autoComplete="given-name"
                value={inputValues.contactFirstName}
                onChange={handleOnChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="contactAddress"
                label="Adresse"
                name="contactAddress"
                autoComplete="street-address"
                value={inputValues.contactAddress}
                onChange={handleOnChange}
              />
              {/* <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="contactStreetNumber"
                label="Street Number"
                name="contactStreetNumber"
  
                value={inputValues.contactStreetNumber}
                onChange={handleOnChange}
              /> */}
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="contactZip"
                label="Postleitzahl"
                name="contactZip"
                autoComplete="postal-code"
                value={inputValues.contactZip}
                onChange={handleOnChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="contactCity"
                label="Ort"
                name="contactCity"
                autoComplete="address-level2"
                value={inputValues.contactCity}
                onChange={handleOnChange}
              />
              {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="contactCountry"
                label="Country"
                name="contactCountry"
                autoComplete="country"
  
                value={inputValues.contactCountry}
                onChange={handleOnChange}
              /> */}
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="contactPhone"
                label="Telefon"
                id="contactPhone"
                autoComplete="phone"
                value={inputValues.contactPhone}
                onChange={handleOnChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth
                name="contactEmail"
                label="E-Mail"
                type="contactEmail"
                id="contactEmail"
                autoComplete="email"
                value={inputValues.contactEmail}
                onChange={handleOnChange}
              />
              <Divider variant="middle" className={classes.divider} />
            </div>
          )}
          <FormControlLabel
            mt={3}
            label={<Typography variant="h6">Bitte senden Sie mir die Antragsunterlagen</Typography>}
            control={
              <Checkbox
                checked={inputValues.isApplicationForm}
                name="isApplicationForm"
                color="primary"
                value={inputValues.isApplicationForm}
                onChange={selectorHandler}
              />
            }
          />
          <FormControlLabel
            mt={3}
            label={<Typography variant="h6">Ich bitte um ein Beratungsgespräch</Typography>}
            control={
              <Checkbox
                checked={inputValues.isConsultation}
                name="isConsultation"
                color="primary"
                value={inputValues.isConsultation}
                onChange={selectorHandler}
              />
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Abschicken
          </Button>
        </form>
        <Alert
          alert={alert}
          setAlert={setAlert}
          updateApplicant={updateApplicantAndSendVerificationEmail}
        />
        <Loading loading={loading} setLoading={setLoading} />
      </div>
    </>
  );
}
