import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Success = () => {
  return (
    <>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          Einzugsmeldung übermittelt
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="h3" variant="h5">
          Ihre Einzugsmeldung wird an die zuständige Behörde übertragen.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="h3" variant="h5">
          Sie erhalten einen Sendebericht per E-Mail.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="h3" variant="h5">
          Vielen Dank, dass Sie sozialhilfe.online genutzt haben.
        </Typography>
      </Box>
    </>
  );
};

export default Success;
