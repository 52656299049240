/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createApplicant = /* GraphQL */ `
  mutation CreateApplicant(
    $input: CreateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    createApplicant(input: $input, condition: $condition) {
      id
      lastName
      firstName
      dob
      address
      zip
      jurisdiction
      fax
      city
      phone
      email
      noFacility
      facilityName
      facilityAddress
      facilityZip
      facilityCity
      facilityDate
      hasContact
      contactFirstName
      contactLastName
      contactAddress
      contactZip
      contactCity
      contactPhone
      contactEmail
      isApplicationForm
      isConsultation
      isVerified
      verifiedAt
      isTelefaxed
      telefaxedAt
      applicationFormURL
      createdAt
      updatedAt
    }
  }
`;
export const updateApplicant = /* GraphQL */ `
  mutation UpdateApplicant(
    $input: UpdateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    updateApplicant(input: $input, condition: $condition) {
      id
      lastName
      firstName
      dob
      address
      zip
      jurisdiction
      fax
      city
      phone
      email
      noFacility
      facilityName
      facilityAddress
      facilityZip
      facilityCity
      facilityDate
      hasContact
      contactFirstName
      contactLastName
      contactAddress
      contactZip
      contactCity
      contactPhone
      contactEmail
      isApplicationForm
      isConsultation
      isVerified
      verifiedAt
      isTelefaxed
      telefaxedAt
      applicationFormURL
      createdAt
      updatedAt
    }
  }
`;
export const deleteApplicant = /* GraphQL */ `
  mutation DeleteApplicant(
    $input: DeleteApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    deleteApplicant(input: $input, condition: $condition) {
      id
      lastName
      firstName
      dob
      address
      zip
      jurisdiction
      fax
      city
      phone
      email
      noFacility
      facilityName
      facilityAddress
      facilityZip
      facilityCity
      facilityDate
      hasContact
      contactFirstName
      contactLastName
      contactAddress
      contactZip
      contactCity
      contactPhone
      contactEmail
      isApplicationForm
      isConsultation
      isVerified
      verifiedAt
      isTelefaxed
      telefaxedAt
      applicationFormURL
      createdAt
      updatedAt
    }
  }
`;
