import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
import logo from "../images/logo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: 150,
      marginRight: "10px",
    },
  })
);

const Header = () => {
  const classes = useStyles();
  return (
    <AppBar position="sticky" color="default">
      <Container maxWidth="xs">
        {/* <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          py={2}
        > */}
        {/* <Typography
            variant="h4"
            color="textSecondary"
            align="center"
            display="inline"
          >
            {"Telefax"}
          </Typography> */}
          <a href="https://sozialhilfe.online">
            <img src={logo} alt="logo" className={classes.logo} />
            </a>
        
        {/* </Box> */}
      </Container>
    </AppBar>
  );
};

export default Header;
