import React from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: "fixed",
      bottom: "0",
      width: "100%",
      backgroundColor: "#324166",
    },
    font: {
      color: "#ADB5BE",
    },
  })
);

const Footer = () => {
  const classes = useStyles();
  return (
    <Box py={2} className={classes.footer}>
      <Typography variant="h6" align="center" className={classes.font}>
        {"sozialhilfe.online"}
      </Typography>
    </Box>
  );
};

export default Footer;
