import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import LandingPage from "./components/LandingPage";
import SendTelefax from "./components/SendTelefax";
import Success from "./components/Success";
import Prompt from "./components/Prompt";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

const App = () => {
  return (
    <>
      <CssBaseline />
      <Header />
      <Container component="main" maxWidth="xs">
        <Router>
          <Route path="/" component={LandingPage} exact />
          <Route path="/telefax/:id?" component={SendTelefax} exact />
          <Route path="/success" component={Success} exact />
          <Route path="/prompt" component={Prompt} exact />
        </Router>
      </Container>
      <Footer />
    </>
  );
};

export default App;
