import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const Prompt = () => {
  return (
    <>
      <Box my={5}>
        <Typography component="h1" variant="h4">
          Einzugsmeldung erstellt
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="h3" variant="h5">
          Ihre Einzugsmeldung wurde erstellt und an Ihre E-Mailadresse übermittelt.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="h3" variant="h5">
          Bitte überprüfen Sie Ihr Postfach und klicken Sie den Bestätigungslink.
        </Typography>
      </Box>
        <Box my={3}>
        <Typography component="h3" variant="h5">
          Die Einzugsmeldung wird im Anschluss per Fax verschickt.
        </Typography>
      </Box>
    </>
  );
};

export default Prompt;
